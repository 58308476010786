.feed-back-tables {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.feed-back-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background-color: rgb(233, 231, 231);
  padding: 5px;
  font-size: 18px;
  font-weight: 500;
  gap: 0.3rem;
}
.feed-back-header > span {
  width: 20%;
}

.feeback-table-body {
  width: 100%;
  height: 96%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  gap: 0.7rem;
  padding: 10px 0px;
  position: relative;
}

.feedback-table-sinble-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  gap: 0.5rem;
  padding: 5px;
  font-size: 17px;
  font-weight: 500;
  color: grey;
}

.feedback-table-sinble-card > span {
  width: 20%;
  overflow-x: auto; /* Allows horizontal scroll */
  white-space: nowrap; /* Prevents wrapping to the next line */
}

.feedback-table-btn-card {
  position: absolute;
  width: 100%;
  bottom: 0px;
  right: 0px;
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
  gap: 1rem;
}

.feedback-table-btn-card > button {
  width: 120px;
  height: 40px;
  cursor: pointer;
  border-radius: 10px;
  font-size: 17px;
  font-weight: 500;
}

.feedback-table-btn-card > :nth-child(1) {
  background-color: transparent;
  border: 2px solid #ff6600;
  outline: none;
  color: #ff6600;
}

.feedback-table-btn-card > :nth-child(2) {
  background-color: #ff6600;
  border: none;
  outline: none;
  color: #fff;
}
