.add-feedback-form-card {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  gap: 1rem;
  width: 100%;
  height: 100%;
}

.add-feedback-form-card > div {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.add-feedback-form-card > input {
  width: 100%;
  height: 45px;
  border: 1px solid lightgrey;
  border-radius: 10px;
  padding: 0px 10px;
  font-size: 17px;
  outline: none;
}

.add-feedback-form-card > button {
  width: 100%;
  height: 40px;
  background-color: #ff6600;
  border-radius: 10px;
  border: none;
  outline: none;
  color: #fff;
  font-size: 17px;
  font-weight: 600;
  cursor: pointer;
}
