.all-modals {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(54, 54, 54, 0.356);
}

.add-todo {
  width: 40%;
  padding: 40px;
  display: flex;
  flex-direction: column;
  border-radius: 10px;
  background-color: #fff;
  gap: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.add-todo-title {
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
}
.add-todo-title > h3 {
  color: lightcoral;
}

.add-todo-title > :nth-child(2) {
  cursor: pointer;
}

.add-todo-form {
  width: 100%;
  /* padding: 50px; */
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.add-todo-input-card {
  display: flex;
  flex-direction: column;
}

.add-todo-input-card > span {
  font-size: 16px;
  font-weight: 500;
  color: grey;
}

.add-todo-input-card > input {
  width: 100%;
  height: 40px;
  border-radius: 10px;
  border: 1px solid grey;
  outline: none;
  padding: 0px 10px;
  font-size: 16px;
}

.add-todo-input-card > textarea {
  width: 100%;
  height: 80px;
  border-radius: 10px;
  border: 1px solid grey;
  outline: none;
  padding: 10px 10px;
  font-size: 16px;
}

.add-todo-form > button {
  width: 100%;
  height: 40px;
  background-color: #ff6600;
  border-radius: 10px;
  outline: none;
  border: none;
  font-size: 17px;
  font-weight: 600;
  color: #fff;
  cursor: pointer;
}
