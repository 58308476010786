.question-main-container {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.question-second-main-card {
  width: 100%;
  display: flex;
  flex-direction: column;

  height: calc(100% - 90px);
  padding: 20px;
  gap: 1rem;
  justify-content: center;
  align-items: center;
}

.question-main-inner-card {
  width: 95%;
  height: 100%;
  padding: 20px;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
}

.question-main-inner-table-header {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.question-main-inner-table-header > :nth-child(1) {
  width: 25%;
  font-size: 18px;
  font-weight: bold;
}

.question-main-inner-table-header > :nth-child(2) {
  width: 30%;

  font-size: 18px;
  font-weight: bold;
}

.question-main-inner-table-header > :nth-child(3) {
  width: 30%;

  font-size: 18px;
  font-weight: bold;
}

.question-main-inner-table-header > :nth-child(4) {
  width: 10%;

  font-size: 18px;
  font-weight: bold;
}

.question-main-inner-table-header > :nth-child(5) {
  width: 5%;

  font-size: 18px;
  font-weight: bold;
}

.question-main-inner-table-body-card {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 90%;
  /* border: 2px solid red; */
  overflow-y: scroll;
}

.question-main-inner-table-single-card {
  width: 100%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  color: grey;
  font-size: 17px;
}

.question-main-inner-table-single-card > :nth-child(1) {
  width: 25%;
}

.question-main-inner-table-single-card > :nth-child(2) {
  width: 30%;
}

.question-main-inner-table-single-card > :nth-child(3) {
  width: 30%;
}

.question-main-inner-table-single-card > :nth-child(4) {
  width: 10%;
}

.question-main-inner-table-single-card > :nth-child(5) {
  width: 4.5%;
  cursor: pointer;
}
