.student-feed-back-main {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  /* padding: 20px; */
  gap: 2rem;
  justify-content: center;
  align-items: center;
  background-color: #fff;
}

.student-feed-back-main > p {
  font-size: 18px;
  font-weight: 500;
  letter-spacing: 1px;
  text-align: center;
  width: 75%;
}

.stuent-feed-back-question-display-main {
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  height: 90%;
  margin-left: 250px;
  margin-bottom: 100px;
  overflow-y: scroll;
}

.student-feed-back-question-single-card {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.student-feed-back-question-single-card > div {
  margin-left: 30px;
}

.student-feed-back-question-single-card > div > div {
  display: flex;
  gap: 0.5rem;
  align-items: center;
}

.student-feed-back-question-single-card > div > div > input {
  transform: scale(1.3);
  margin-top: 5px;
}
.student-feed-back-question-single-card > div > div > span {
  font-size: 20px;
  font-weight: 500;
}

.student-feed-back-textarea {
  width: 100%;
  height: 140px;
}

.student-feed-back-textarea > button {
  width: 150px;
  height: 40px;
  background-color: #ff6600;
  border: none;
  outline: none;
  border-radius: 10px;
  color: #fff;
  cursor: pointer;
  font-size: 17px;
  font-weight: 500;
}
