.header-container {
  width: 100%;
  height: 90px;
  display: flex;
  gap: 2rem;
  padding: 0px 40px;
  position: relative;
  box-shadow: rgba(0, 0, 0, 0.16) 0px 1px 4px;
  /* position: fixed;
  top: 0;
  left: 0; */
}

.header-container-img {
  width: 90px;
  padding: 10px;
  /* border: 2px solid red; */
}

.header-second-card {
  width: 80%;
  /* border: 2px solid red; */
  display: flex;
  flex-direction: column;
  /* gap: 0.5rem; */
  height: 100%;
}

.header-second-single-card {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  gap: 2rem;
  border-bottom: 1px solid lightgrey;
}

.header-second-single-card > span {
  font-size: 16px;
  font-weight: 600;
  font-family: "Lato" sans-serif;
  color: grey;
}
.header-second-single-card > span > :nth-child(1) {
  text-decoration: none;
  color: inherit;
}

.header-second-card-second-card {
  width: 100%;
  height: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* border: 2px solid red; */
}
.header-second-card-second-card > span {
  font-size: 16px;
  font-weight: 700;
  font-family: "Lato" sans-serif;
}

.header-second-single-card > span .active {
  color: #ff6600;
}

.header-icons {
  display: none;
}

/* @media (max-width: 768px) {
  .header-container {
    align-items: center;
    justify-content: space-between;
  }
  .header-icons {
    display: block;
    cursor: pointer;
  }
 
} */
