.home-first-left {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-radius: 10px;
  box-shadow: rgba(149, 157, 165, 0.2) 0px 8px 24px;
  padding: 30px;
  height: 370px;
}
.home-first-left > :nth-child(1) {
  display: flex;
  justify-content: space-between;
  align-items: center;
}
.home-first-left > :nth-child(1) > :nth-child(2) {
  cursor: pointer;
}

.home-first-left > :nth-child(2) {
  width: 100%;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
}

.home-first-left > :nth-child(2) > * {
  width: 250px;
  height: 100px;
  border-radius: 10px;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  justify-content: center;
  align-items: center;
  color: #fff;
}

.home-first-left > :nth-child(2) > *:nth-child(1) {
  background-color: #ff8370;
}

.home-first-left > :nth-child(2) > *:nth-child(2) {
  background: #ff9433;
}

.home-first-left > :nth-child(2) > *:nth-child(3) {
  background: #e96c6ccc;
}

.home-first-left > :nth-child(2) > *:nth-child(4) {
  background-color: #f3c233;
}
